.root {
  @apply typo-cta-button;
  padding: 0.2rem 3rem;
  border-radius: 3rem;

  // tailwindcss classes
  @apply typo-cta-button;
  @apply text-deepBlue;

  &.primary {
    // this is a tailwindcss class
    @apply bg-brightBlueLight;
  }
  &.primary-outline {
    background-color: none;
    border: 0.2rem solid theme('colors.brightBlueLight');
    @apply text-deepBlue py-0;
  }
  &.secondary {
    // this is a tailwindcss class
    @apply bg-teal;
  }
  &.outline {
    background-color: none;
    border: 2px solid theme('colors.teal');
    @apply text-teal;
  }
  &.outline-small {
    padding: 0rem 1.6rem;
    background-color: none;
    border: 2px solid theme('colors.teal');
    @apply text-teal;
  }
}