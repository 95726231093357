.navbar {
    @apply bg-deepBlue flex justify-between;

    height: 8rem;

    @screen sm {
        height: 8rem;
    }
    @screen md {
        height: 10rem;
    }
    @screen lg {
        height: 11rem;
    }
    @screen xl {
        height: 12rem;
    }

    &__image {
        @apply bg-white cursor-pointer z-20;
        border-radius: 0rem 0rem 2rem 2rem;

        margin-left: 2.4rem;
        height: 6.4rem;
        padding: 1.28rem 1.44rem;
        @screen sm {
            margin-left: 2.4rem;
            height: 6.4rem;
            padding: 1.28rem 1.44rem;
        }
        @screen md {
            height: 8rem;
            margin-left: 7.2rem;
            padding: 1.6rem 1.8rem;
        }
        @screen lg {
            height: 9rem;
            padding: 1.8rem 2rem;
        }
        @screen xl {
            height: 10rem;
            padding: 2rem 2.2rem;
        }

        img {
            height: 3.84rem;
            width: 3.84rem;
            @screen sm {
            height: 3.84rem;
            width: 3.84rem;
            }
            @screen md {
            height: 4.8rem;
            width: 4.8rem
            }
            @screen lg {
            height: 5.4rem;
            width: 5.4rem;
            }
            @screen xl {
            height: 6rem;
            width: 6rem;
            }
        }
    }

    &__nav_items {
        @apply  text-white flex;

        &__large {
            @apply hidden gap-20 ml-20;

            @screen lg {
                @apply flex;
            }
        }
    }

    &__nav {
        @apply text-white typo-p my-auto;
        @apply transition duration-150 border-b-2 border-transparent hover:border-white hover:cursor-pointer z-20;
      }

    &__nav-item-login-mobile {
        align-items: center;
        display: flex;
        margin-right: 3.3rem;
        @screen sm {
            display: flex;
            margin-right: 2.4rem
        }
        @screen md {
            display: flex;
            margin-right: 7.2rem;
        }
        @screen lg {
            display: none;
        }
    }

    &__icon {
        align-items: center;
        margin-left: 1.4rem;
        height: auto;
      }
}

.wrapper {
    @apply relative;
}

.mobile-menu {
    @apply bg-deepBlue;
    position: absolute;
    height: 100vh;
    width: 100%;
    min-height: 64rem;
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    z-index: 4;
    transform: translateX(110%);
    transition: transform .7s cubic-bezier(.165,.84,.44,1), .5s cubic-bezier(.165,.84,.44,1) ease-in-out;

    &__close {
        height: auto;
        padding-top: 2rem;
        display: flex;
        flex-direction: row-reverse;
    }

    &__nav-items {
        @apply pt-10;
        display: flex;
        z-index: 4;
        flex-direction: column;
      }

    &__nav-item {
    height: auto;
    @apply text-white;
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin-bottom: 3rem;
    opacity: 0;
    z-index: 4;
    transform: translateY(-1rem);
    }

    &__nav-item-dropdown {
        height: auto;
        @apply text-white;
        font-size: 2.5rem;
        line-height: 2.75rem;
        margin-bottom: 1rem;
        margin-left: 2.5rem;
        z-index: 4;
        opacity: 0;
        transform: translateY(-1rem);
        }
}

.open-menu {
    transform: translateX(0);
  }

.fade-in-text {
transition: opacity .8s translate .8s cubic-bezier(.165,.84,.44,1),-webkit-transform .8s cubic-bezier(.165,.84,.44,1);
transition-duration: .44s;
transform: translateY(0);
opacity: 1;
}

