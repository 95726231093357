.root {
  @apply typo-p-small leading-8 flex rounded-lg border border-solid relative;
  padding: 1.4rem 1.1rem 1.3rem 3.5rem;
  min-height: 4.9rem;

  & svg {
    @apply absolute;
    left: 1.6rem;

    &.alert {
      top: 1.6rem;
    }
    
    &.success {
      top: 1.4rem;
    }

    &.error {
      top: 1.5rem;
    }
  }
  
  &.alert-root {
    @apply border-brightBlueDark text-brightBlueDark;
  }
  
  &.success-root {
    @apply border-successMessageColor bg-backgroundSuccessMessage;
    font-weight: 400;
    padding: 1.5rem 1.1rem 1.2rem 4.5rem;
  }

  &.error-root {
    @apply border-errorRed bg-backgroundErrorMessage typo-p;
    font-weight: 500;
    line-height: 2.8rem;
    padding: 1.5rem 1.1rem 1.2rem 4.8rem;
  } 
  
}