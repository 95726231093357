.root {
    @apply flex flex-col justify-center bg-mutedBlue pb-80 mx-3;

    @screen sm {
        @apply mx-10
    }

    @screen md {
        @apply max-w-4xl mx-auto;
    }
    @screen lg {
        @apply max-w-5xl mx-auto;
    }
    @screen xl {
        @apply max-w-7xl mx-auto;
    }

    &__header {
        @apply text-deepBlue text-center mb-10;
    }

    &__body {
        @apply typo-h2 text-grey text-center;
    }
}