.root {
    @apply bg-mutedBlue py-40;

    &__header {
        @apply text-deepBlue text-center mx-10 font-medium;

    }

    &__images {
        @apply flex flex-wrap justify-center;
        @apply gap-20 my-40 mx-12;

        @screen md {
            @apply gap-20 my-40 mx-40;
            
        }
        @screen lg {
            @apply gap-36 my-40 mx-60;
        }
        @screen xl {
            @apply gap-36 my-40 mx-80;
        }
    }

    &__image {
        @apply object-contain;

        height: 5.5rem;

        @screen md {
            height: 6rem;
            
        }
        @screen lg {
            height: 6rem;
            
        }
        @screen xl {
            height: 7.5rem;
        }
    }
}