.footer {
    @apply bg-deepBlue overflow-hidden px-2 py-5;

    @screen sm {
        @apply px-3
    }
    @screen md {
        @apply px-10 
    }
    @screen lg {
        @apply px-20 
    }
    @screen xl {
        @apply px-32 
    }

    &__mission {
        @apply text-white typo-p-medium;
    }

    &__copy-right-text {
        @apply text-center text-white mt-5 mb-5 typo-p-small;
    }
    
    &__image {
        @apply mx-auto bg-white p-2;
        border-radius: 20px 0 20px 0
    }
    &__bottom {
        @apply pt-10 flex justify-between;
    }

    &__social-media-wrapper {
        @apply flex flex-row gap-3 pr-20;
    }

    &__social-media-container {
        @apply flex flex-col gap-3;
    }

    &__social-media-icon {
        @apply h-12 w-12 cursor-pointer fill-white
    }
}