// boilerplate basics

html {
  // set default font size to 10px and use rem for all other font sizes
	font-size: 10px;

  &,
  body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    position: relative;
    overflow-x: hidden;
  }
}
