.root {
  overflow: hidden;
  padding-top: 2.4rem;
  border-top: 0.1rem solid theme('colors.separatorLineBorder');

  &:last-child {
    border-bottom: 0.1rem solid theme('colors.separatorLineBorder');
  }

  @screen md {
    padding-top: 1.6rem;
  }
}

.topContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.9rem;
  align-items: center;
  cursor: pointer;
}

.description {
  @apply typo-p text-deepBlue;
}

.detail {
  transition: all 0.3s ease;
  @apply typo-p-small text-deepBlue;
  font-weight: normal;
  max-height: 2.4rem;
  visibility: hidden;
  min-height: 0;
  margin-top: 0;
  opacity: 0;

  @screen md {
    max-height: 1.6rem;
  }

  &Open {
    opacity: 1;
    display: block;
    min-height: fit-content;
    max-height: fit-content;
    visibility: visible;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;

    @screen md {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }
}
