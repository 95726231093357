.root {
    @apply px-5 pt-20 z-0;
    @apply h-screen;

    @screen sm {
        @apply px-10;
    }
    @screen md {
        @apply px-40;
    }
    @screen lg {
        @apply flex justify-between px-80 pb-96 h-fit;
    }
    @screen xl {
        @apply px-96 pb-96 h-fit;
    }


}

.text-container {

    @screen sm {
        width: 325px;
        @apply content-center;
    }
    @screen md {
        width: 550px;
        
    }
    @screen lg {
        width: 700px;
    }
    @screen xl {
        width: 850px;
    }
}

.button-container {
    @apply flex gap-10;
}