.email-address, .contact-name {
    margin-top: 2.4rem;
}

.message {
    @apply typo-p;
    label {
        margin-top: 2.2rem;
        @apply block mb-2
    }
    textarea {
        border: 0.1rem solid theme('colors.deepBlue');
        border-radius: 0.5rem;
        @apply cursor-text px-4 outline-none block p-2.5 w-full;
    }
    textarea:focus {
        border: 0.1rem solid theme('colors.brightBlue');
    }
}

.btn-send-message {
    margin-top: 3.2rem;
    float: right;
}

.success-message {
    @apply typo-p;
    margin-top: 2rem;
}
