.root {
  @apply bg-white flex flex-col items-center;
  border-radius: 3rem;
  padding: 7.1rem 0rem;

  @screen md {
    padding: 12rem 0rem 10.4rem;
  }

  @screen lg {
    padding: 12rem 0rem 9.1rem;
  }

  @screen xl {
    padding: 11.9rem 0rem 9.4rem;
  }

  .title {
    @apply text-center typo-h1 text-deepBlue cursor-default;
    margin: 0rem 2.8rem 3.2rem;

    @screen md {
      margin: 0rem 1.5rem 2rem;
    }

    @screen lg {
      margin: 0rem 11.7rem 2rem;
    }
  }

  .content {
    @apply text-center typo-p text-grey cursor-default;
    margin: 0rem 2.8rem 3.4rem;

    @screen md {
      margin: 0rem 9.8rem 3.2rem;
    }

    @screen lg {
      margin: 0rem 19.8rem 4rem;
    }

    @screen xl {
      margin: 0rem 15.8rem 2.2rem;
    }
  }
}
