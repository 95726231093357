.root {
  border: 0.1rem solid theme('colors.athensGray');
  border-radius: 3rem;
  padding: 6.4rem 3.2rem 4rem 2.4rem;

  @screen md {
    padding: 4.8rem 3.2rem 3.2rem;
  }

  @screen lg {
    padding: 4.8rem 5.6rem 3.2rem;
  }

  @screen xl {
    padding: 4.5rem 10.9rem 6.2rem;
  }
}

.headline {
  @apply text-deepBlue;
  margin-bottom: 2.8rem;

  @screen xl {
    margin-bottom: 3.5rem;
  }
}
