.root {
  @apply typo-p;
  @apply flex flex-col;

  & .error-label {
    @apply typo-p;
    @apply text-red-500;
  }

  & .error-input {
    border: 0.1rem solid theme('colors.errorRed');
  }

  & label {
    @apply typo-p;
  }

  & input {
    border: 0.1rem solid theme('colors.deepBlue');
    border-radius: 0.3rem;
    height: 4.8rem;
    @apply cursor-text px-4 outline-none;
  }
  & input:focus {
    border: 0.1rem solid theme('colors.brightBlue');
  }

  & .error-message {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 400;
    @apply text-red-500;
  }
}