.root {
    @apply flex flex-wrap justify-center lg:gap-20;
}

.selling-point-container {
    @apply max-w-xl flex my-5;
}

.selling-point-text-container {
    @apply ml-2;
}

.text {
    @apply text-white typo-p-large font-bold;

    &__sub {
        @apply typo-p-medium text-white;
    }
}