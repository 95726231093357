.dropdown-nav-item {
    @apply text-white typo-p my-auto;
    @apply flex;
    @apply transition duration-150 border-b-2 border-transparent hover:border-white hover:cursor-pointer z-20;

}

.dropdown {
    @apply bg-celloBlue absolute z-10 top-0 right-0 left-0;

    @screen lg {
        height: 275px;
    }
    @screen xl {
        height: 325px;
    }

    &__items {
        @apply relative grid grid-cols-3 py-40;

        @screen lg {
            @apply px-20
        }
        @screen xl {
            @apply px-40
        }
    }
}