.whyUseJib {
    @apply bg-mutedBlue py-32 px-2;

    &__title {
        @apply text-deepBlue text-center pb-20
    }
}

.item-container {
    @apply flex flex-wrap px-5 md:justify-center gap-10 my-5;
}