.root {
    @apply py-32 px-5;
}

.header {
    @apply text-center py-10;
}

.step-container {
    @apply flex flex-wrap  gap-32 md:justify-center;
}

.text {
    @apply typo-h2;

    &__sub {
        @apply typo-h4;
    }
}
