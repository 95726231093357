.root {
    @apply pt-20 pb-2
}

.primary-title {
    @apply text-white;
    font-size: 3rem;

    @screen sm {
        font-size: 2.75rem;
        line-height: 4.25rem;
    }
    @screen md {
        font-size: 4.75rem;
        line-height: 6rem;
        // @apply text-9xl;
    }
    @screen lg {
        font-size: 5.5rem;
        line-height: 6.5rem;
    }
    @screen xl {
        font-size: 6.75rem;
        line-height: 8rem;
    }

}

.secondary-title {
    @apply text-altoGrey py-10 typo-h3;

    @screen sm {
        font-size: 1.75rem;
        line-height: 2.75rem;
    }
    @screen md {
        font-size: 3rem;
        line-height: 4rem;
    }
    @screen lg {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
    @screen xl {
        font-size: 4rem;
        line-height: 5rem;
    }
}